import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { Icon } from "react-icons-kit"
import { envelope } from "react-icons-kit/fa/envelope"
import AdmNavbar from "../../components/admNavbar"
import Footer from "../../components/footer"
import ErrorModal from "../../components/error-modal"
import { VerifyUserPermission } from "../../functions/verifyUserPermision"
import { handleVerifyEmail, handleCreateNewAccoun } from "../../services/auth"
import { getRoles } from "../../services/admin"
import "../../css/multilogica-theme.scss"

import {
  Container,
  Row,
  Col,
  Spinner,
  Button,
  Form,
  Modal,
} from "react-bootstrap"

import "bootstrap/dist/css/bootstrap.min.css"

function GenerateAccount() {
  const [formEmail, setFormEmail] = useState("")
  const [formRole, setFormRole] = useState("")
  const [userVerified, setUserVerified] = useState(false)
  const [errorField, setErrorField] = useState([])
  const [spinnerEntryActive, setSpinnerEntryActive] = useState(false)
  const [buttonContinueEnabled, setButtonContinueEnabled] = useState(true)
  const [emailExists, setEmailExists] = useState(false)
  const [modalEmailSent, setModalEmailSent] = useState(false)
  const [showErrorConnModal, setShowErrorConnModal] = useState(false)
  const [roles, setRoles] = useState([])
  const [rolesOptions, setRolesOptions] = useState([])
  const [jwt, setJwt] = useState("")

  useEffect(() => {
    async function fetchData(jwt) {
      await getRoles(jwt, { permission: "generate-account" }).then(res => {
        setRoles(res.data)
        setJwt(jwt)
      })
    }
    fetchData(localStorage.getItem("jwt"))
  }, [])

  useEffect(() => {
    let rolesAux = [<option key={0}>selecione</option>]
    Object.keys(roles).forEach((key, i) => {
      rolesAux.push(<option key={i + 1}>{roles[key]["userRole"]}</option>)
    })
    setRolesOptions(rolesAux)
  }, [roles])


  useEffect(() => {
    const verifyUser = async () => {
      const userHasPermission = await VerifyUserPermission("generate-account")
      if (!userHasPermission) {
        navigate("/403")
      } else {
        setUserVerified(true)
      }
    }
    if (!userVerified) verifyUser()
  }, [userVerified])


  const formateField = evt => {
    evt.preventDefault()
    if (evt.target.id === "formEmail") {
      setFormEmail(evt.target.value)
      if (verifyEmailString(evt.target.value)) {
        removeError(evt.target.id)
      }
    }
  }

  const verifyEmailString = email => {
    const re = new RegExp(
      '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
    )
    if (re.test(email)) {
      return true
    } else {
      return false
    }
  }

  const removeError = errName => {
    if (errorField.includes(errName)) {
      let errorFieldAux = errorField
      errorFieldAux.splice(errorFieldAux.indexOf(errName), 1)
      setErrorField(errorFieldAux)
    }
  }

  const processNewAccount = async () => {
    await handleCreateNewAccoun(jwt, {
      userName: "   ",
      email: formEmail,
      password: Math.random().toString(36).slice(-10),
      role: formRole,
    }).then(res => {
      if (!res.isAxiosError) {
        setModalEmailSent(true)
      } else {
        setShowErrorConnModal(true)
      }
    })
  }

  const verifyEmailDB = async () => {
    let errorFieldAux = []
    if (verifyEmailString(formEmail)) {
      setButtonContinueEnabled(false)
      setSpinnerEntryActive(true)
      await handleVerifyEmail(formEmail).then(res => {
        if (typeof res.isAxiosError === "undefined" || !res.isAxiosError) {
          if (res.data.isEmailRegistred) {
            errorFieldAux.push("formEmail")
            setEmailExists(true)
          }
        } else {
          setEmailExists(false)
          setSpinnerEntryActive(false)
        }
      })
    } else {
      errorFieldAux.push("formEmail")
    }
    if (formRole === "" || formRole === "selecione") {
      errorFieldAux.push("formRole")
    }
    if (errorFieldAux.length > 0) {
      setErrorField(errorFieldAux)
    } else {
      processNewAccount()
    }
  }

  const onDropdownSelected = evt => {
    setFormRole(evt.target.value)
    if (evt.target.value !== "" && evt.target.value !== "selecione") {
      removeError(evt.target.id)
    }
  }

  const removeModal = () => {
    setModalEmailSent(false)
    navigate("/user/account/")
  }

  const removeErrorConnModal = () => {
    setShowErrorConnModal(!showErrorConnModal)
  }

  return (
    <>
      <AdmNavbar />
      <ErrorModal
        modalErro={showErrorConnModal}
        removeErrorConnModal={removeErrorConnModal}
      />
      <Modal show={modalEmailSent}>
        <Modal.Body>
          <p>
            Enviamos um email com um link para o usuário registrar uma senha.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={removeModal}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="height-40" />
      <div>
        <Container>
          <Row>
            <Col>
              <Container className="info-page">
                <Row className="align-items-center">
                  <Col xs={12}>
                    <div className="text-center">
                      <h3>Criar um novo usuário</h3>
                    </div>
                  </Col>
                </Row>
                {!userVerified ? (
                  <div>
                    <Spinner animation="border" size="sm" variant="primary" />
                  </div>
                ) : (
                  <>
                    <Row>
                      <Col sm={4} />
                      <Col sm={4}>
                        <div className="height-40" />
                        <Form.Group controlId="formEmail">
                          <Form.Label>
                            <Icon size={"16px"} icon={envelope} />
                            &nbsp;email
                          </Form.Label>
                          <Form.Control
                            type="email"
                            value={formEmail}
                            onChange={evt => formateField(evt)}
                            className={
                              errorField.includes("formEmail")
                                ? "errorBorder"
                                : ""
                            }
                          />
                        </Form.Group>
                        {emailExists ? (
                          <p>Esse email já está registrado.</p>
                        ) : (
                          <p>&nbsp;</p>
                        )}
                        <Form.Group controlId="formRole">
                          <Form.Label>Função</Form.Label>
                          <Form.Control
                            as="select"
                            onChange={evt => onDropdownSelected(evt)}
                            className={
                              errorField.includes("formRole")
                                ? "errorBorder"
                                : ""
                            }
                          >
                            {rolesOptions}
                          </Form.Control>
                        </Form.Group>
                        <div style={{ height: "56px" }} />
                        <Button
                          variant="primary"
                          onClick={verifyEmailDB}
                          disabled={!buttonContinueEnabled}
                          style={{ float: "left" }}
                        >
                          Gerar
                        </Button>
                        <div
                          style={{
                            display: spinnerEntryActive ? "block" : "none",
                            float: "left",
                            margin: "4px 0 0 6px",
                          }}
                        >
                          <Spinner animation="border" variant="secondary" />
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="height-40" />
      <Footer />
    </>
  )
}

export default GenerateAccount
