import { clearConsole } from "../functions/clearConsole" 
const axios = require("axios")

const url = process.env.GATSBY_API_URL


export const handleVerifyEmail = async email => {
  console.log('handleVerifyEmail url',url)
  try {
    return await axios({
      url: url + "/auth/verify-email",
      method: "post",
      data: {
        email: email,
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
  } catch (error) {
    clearConsole()
    console.log('handleVerifyEmail error',error)
    return error
  }
}

export const sigin = async data => {
  try {
    return await axios({
      method: "post",
      url: url + "/auth/signin",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    })
  } catch (error) {
    clearConsole()
    console.log('sigin error',error)
    return error
  }
}

export const getUser = async data => {
  try {
    return await axios({
      method: "get",
      url: url + "/auth/get-user-data",
      headers: {
        Authorization: "Bearer " + data,
      },
    })
  } catch (error) {
    clearConsole()
    console.log('getUser error',error)
    return error
  }
}

export const saveUser = async (jwt,data) => {
  try {
    return await axios({
      method: "patch",
      url: url + "/auth/save-user-data",
      headers: {
        Authorization: "Bearer " + jwt,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data)
    })
  } catch (error) {
    clearConsole()
    console.log('saveUser error',error)
    return error
  }
}

export const handleCreateUser = async data => {
  try {
    return await axios({
      method: "post",
      url: url + "/auth/signup",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    })
  } catch (error) {
    clearConsole()
    console.log('handleCreateUser error',error)
    return error
  }
}

export const handleConfirmEmail = async data => {
  try {
    return await axios({
      method: "post",
      url: url + "/auth/confirm-email",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    })
  } catch (error) {
    clearConsole()
    console.log('handleConfirmEmail error',error)
    return error
  }
}

export const handleSendNewEmail = async data => {
  try {
    return await axios({
      method: "post",
      url: url + "/auth/send-new-email",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    })
  } catch (error) {
    clearConsole()
    console.log('handleSendNewEmail error', error)
    return error
  }
}

export const handleSendPasswordRecovery = async data => {
  try {
    return await axios({
      method: "post",
      url: url + "/auth/send-password-recovery",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    })
  } catch (error) {
    clearConsole()
    console.log('handleSendPasswordRecovery error', error)
    return error
  }
}

export const handleCreateNewAccoun = async (jwt, data) => {
  try {
    return await axios({
      method: "post",
      url: url + "/auth/create-account",
      headers: {
        Authorization: "Bearer " + jwt,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    })
  } catch (error) {
    clearConsole()
    console.log('handleCreateNewAccoun error',error)
    return error
  }
}

export const handleChangePassword = async data => {
  try {
    return await axios({
      method: "post",
      url: url + "/auth/change-password",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    })
  } catch (error) {
    clearConsole()
    console.log("handleChangePassword error", error)
    return error
  }
}
