import React from "react"
import { Button, Modal } from "react-bootstrap"

class ErrorModal extends React.Component {

  render() {
    return (
      <>
        <Modal show={this.props.modalErro}>
          <Modal.Body>
            <h5>Erro - sem contato com a base de dados</h5>
            <h5>Por favor tente novamente mais tarde.</h5>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.removeErrorConnModal}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default ErrorModal
